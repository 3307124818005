import { GetOldValueOnInput } from "@/Helpers/GetValue";
import moment from "moment";
// import queryOption from "@/Helpers/queryOption";
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "Customer",
  // props: ["calendars", "pagination"],
  data() {
    return {
      isDisableExportButton: true,
      optimizedCalendars: [],
      selectPlanCalendar: null,
      plan_calendar_month_year_filter_data: [],
      monthYearPicker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      is_month_year_picker_show: false,
      created_date_filter: "",
      created_date_filter_menu: false,
      monthOfPlanData: [],
      customer_type_value: [
        { label: "ຄົວເຮືອນ", value: "home" },
        { label: "ທຸລະກິດ", value: "company" },
      ],
      selectedCustomerType: null,
      selectedVillage: [],
      selectedDistrict: "",
      districts: [],
      villages: [],

      loading: false,
      calendarId: "",
      // Pagination
      calendars: [],
      allCalendars: [],
      successes: [],
      offset: 12,
      pagination: {},
      per_page: 15,
      search: "",
      oldVal: "",
      summary: {},
      countPause: {},
      statuses: [],
      plan_monthId: this.$route.params.id,
      headers: [
        { text: 'ລຳດັບຄວາມສຳຄັນ', value: 'priority' },
        { text: 'ໄອດີ', value: 'customer_id' },
        { text: 'ລູກຄ້າ', value: 'full_name' },
        { text: 'ເບີໂທ', value: 'phone' },
        {
          text: 'ສະຖານະເກັບ',
          value: 'can_collect',
        },
        {
          text: 'ບ້ານ',
          value: 'village_name',
        },
        {
          text: 'ເມືອງ',
          value: 'district_name',
        },
        {
          text: 'ຈຳນວນຂີ້ເຫື້ຍອ',
          value: 'amount',
          align: 'center',
        },
        {
          text: 'ຫົວໜ່ວຍ',
          value: 'unit',
          align: 'center',
        },
        {
          text: 'ສະຖານະ',
          value: 'status',
          align: 'center',
        },
        {
          text: 'ວັນທີສ້າງ',
          value: 'created_at',
          align: 'center',
        },
        {
          text: 'ວັນທີເກັບ',
          value: 'collected_at',
          align: 'center',
        },
        { text: '', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(['planCalendarId', "allPlanCalendarDetailPagination", "allPlanCalendarDetailPendingPagination", "allPlanCalendarDetailRejectPagination", "allPlanCalendarDetailSuccessPagination", "allPlanCalendarDetailWaitConfirmPagination", "allPlanCalendarDetailCancelPagination"]),
    params() {
      return {
        villages: this.selectedVillage,
        district_id: this.selectedDistrict,
        without_month_info: true,
        statuses: this.statuses,
        created_date: this.created_date_filter,
        per_page: this.per_page,
        page: this.pagination.current_page,
        customer_type: this.selectedCustomerType,
      };
    },
    onlyFilter() {
      return {
        villages: this.selectedVillage,
        district_id: this.selectedDistrict,
        // statuses: this.statuses,
        created_date: this.created_date_filter,
        // customer_type: this.selectedCustomerType,
      };
    },
    dragCalendars() {
      return this.calendars;
    },
  },
  methods: {
    ...mapActions([
      'addPlanCalendarId',
      'addPlanCalendarDetailData',
      'addPlanCalendarDetailPagination',
      'addPlanCalendarDetailPendingData',
      'addPlanCalendarDetailPendingPagination',
      'addPlanCalendarDetailRejectData',
      'addPlanCalendarDetailRejectPagination',
      'addPlanCalendarDetailSuccessData',
      'addPlanCalendarDetailSuccessPagination',
      'addPlanCalendarDetailWaitConfirmData',
      'addPlanCalendarDetailWaitConfirmPagination',
      'addPlanCalendarDetailCancelData',
      'addPlanCalendarDetailCancelPagination',
    ]),
    mergedCalendars(data) {
      return data.map((item) => {
        let amount = 0;
        let amountColor = "primary";

        switch (item.collection_type) {
          case "bag":
          case "chartered":
          case "32km":
          case "infect":
            amount = item.bag;
            break;
          case "fix_cost":
            amount = "";
            break;
          default:
            amount = item.container;
            amountColor = "success";
            break;
        }

        const statusColor = this.statusColor(item.status);
        const amountCollectionType = this.getUnit(item.collection_type);
        const isPauseColor = item.is_pause ? "orange" : "green";
        const isPauseLa = item.is_pause ? "ຢຸດກ່ອນ" : "ໃຫ້ເກັບ";
        const customerCanCollectColor = item.route_plan_detail.customer
          .can_collect
          ? "success"
          : "error";
        const customerCanCollectLa = item.route_plan_detail.customer.can_collect
          ? "ເກັບໄດ້"
          : "ເກັບບໍ່ໄດ້";
        return {
          ...item,
          created_at: moment(item.created_at).format("DD-MM-YY hh:mm"),
          date: moment(item.date).format("DD-MM-YY hh:mm:ss"),
          status_color: statusColor,
          amount,
          amount_color: amountColor,
          amount_collection_type: amountCollectionType,
          is_pause_color: isPauseColor,
          is_pause_la: isPauseLa,
          customer_can_collect_color: customerCanCollectColor,
          customer_can_collect_la: customerCanCollectLa,
        };
      });
    },

    formatDate(date) {
      const [year, month, theDate] = date.split('-');
      return `${year}-${month}-${theDate}`;
    },

    fetchPlanCalendarByMonthYear() {
      this.selectPlanCalendar = null;
      this.plan_calendar_month_year_filter_data = [];
      // Convert the string to a Date object
      const dateSelected = new Date(this.monthYearPicker);
      const yearFilter = dateSelected.getFullYear();
      const monthFilter = (dateSelected.getMonth() + 1).toString().padStart(2, '0');
      const dayFilter = (dateSelected.getDate()).toString().padStart(2, '0');

      this.$axios
        .get(
          `report/plan-month/plan-calendar`,
          {
            params: {
              year: yearFilter, month: monthFilter, day: dayFilter, route_plan_type: this.selectedCustomerType,
            },
          },
        )
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.plan_calendar_month_year_filter_data = res.data.data;
            }, 100);
          }
        })
        .catch((error) => {
          this.$store.commit("Loading_State", false);
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: error.response.data.message,
          });
        });
    },
    fetchAddress() {
      this.$axios
        .get("info/address", { params: { filter: "ນະຄອນຫລວງວຽງຈັນ" } })
        .then((res) => {
          if (res.data.code == 200) {
            setTimeout(() => {
              this.address = res.data.data;
              this.address.map((item) => {
                this.districts = item.districts;
              });
            }, 300);
          }
        })
        .catch(() => {});
    },
    commitSearchButton() {
      this.isDisableExportButton = false;
      this.fetchData({ isRefresh: true });
    },
    fetchVillage() {
      this.$axios
        .get(`info/district/${this.selectedDistrict}/village`)
        .then((res) => {
          if (res.data.code == 200) {
            setTimeout(() => {
              this.villages = res.data.data;
            }, 300);
          }
        })
        .catch(() => {});
    },
    downloadExelWithFilter() {
      this.$store.commit("Loading_State", true);
      const exportStatus = [];
      // let switchBackFiiler = false;
      switch (this.$route.query.tab) {
        case "trash-all":
          // exportStatus.push('');
          // switchBackFiiler = true;
          break;
        case "trash-pending":
          exportStatus.push("pending");
          break;
        case "wait-to-confirm":
          exportStatus.push("wait_to_confirm");
          break;
        case "trash-success":
          exportStatus.push("success");
          break;
        case "trash-reject":
          exportStatus.push("reject");
          break;
        case "trash-cancel":
          exportStatus.push("canceled");
          break;
      }
      const exportQuery = { ...this.params };

      exportQuery.statuses = exportStatus;
      this.$axios
        .get(`report/plan-calendar/${this.planCalendarId}/detail`, {
          params: { ...exportQuery, export: true },
        })
        .then((response) => {
          if (response.status == 200) {
            if (response.data.data.download_link != null) {
              window.open(response.data.data.download_link);
            }
          }

          this.$store.commit("Loading_State", false);
        })
        .catch((error) => {
          this.$store.commit("Toast_State", {
            value: true,
            color: "error",
            msg: error,
          });
          this.$store.commit("Loading_State", false);
        });
    },
    async fetchData({ isRefresh = false } = {}) {
      try {
        this.$store.commit("Loading_State", true);
        const res = await this.$axios
          .get(`report/plan-calendar/${this.planCalendarId}/detail`, {
            params: {
              page: isRefresh ? 1 : this.allPlanCalendarDetailPagination.current_page,
              per_page: this.per_page,
              order_by: 'piority',
              ...this.onlyFilter,
            },
          });
        if (res.data.code === 200) {
          setTimeout(async () => {
            (this.calendars = []), (this.calendars = res.data.data.data);
            this.summary = res.data.data.summary;
            this.countPause = res.data.count_pause;
            this.addPlanCalendarDetailPagination(res.data.data.pagination);
            const data = this.mergedCalendars(this.calendars);
            this.addPlanCalendarDetailData(data);
            if (isRefresh) {
              await Promise.all([
                this.fetchPendingData({ isRefresh }),
                this.fetchRejectData({ isRefresh }),
                this.fetchSuccessData({ isRefresh }),
                this.fetchWaitConfirmData({ isRefresh }),
                this.fetchCancelData({ isRefresh }),
              ]);
            }
            this.$store.commit("Loading_State", false);
          }, 100);
        }
      } catch (error) {
        this.$store.commit("Loading_State", false);
        this.$store.commit("Toast_State", {
          value: true,
          color: "error",
          msg: error.response.data.message,
        });
      }
    },
    fetchPendingData({ isRefresh = false } = {}) {
      this.$axios
        .get(`report/plan-calendar/${this.planCalendarId}/detail`, {
          params: {
            page: isRefresh ? 1 : this.allPlanCalendarDetailPendingPagination.current_page,
            per_page: this.per_page,
            statuses: ['pending'],
            order_by: 'piority',
            ...this.onlyFilter,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            setTimeout(() => {
              const data = this.mergedCalendars(res.data.data.data);
              console.log("pending:", data);
              this.addPlanCalendarDetailPendingData(data);
              this.addPlanCalendarDetailPendingPagination(res.data.data.pagination);
            }, 100);
          }
        });
    },
    fetchRejectData({ isRefresh = false } = {}) {
      this.$axios
        .get(`report/plan-calendar/${this.planCalendarId}/detail`, {
          params: {
            page: isRefresh ? 1 : this.allPlanCalendarDetailRejectPagination.current_page,
            per_page: this.per_page,
            statuses: ['reject'],
            order_by: 'piority',
            ...this.onlyFilter,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            setTimeout(() => {
              const data = this.mergedCalendars(res.data.data.data);
              this.addPlanCalendarDetailRejectData(data);
              this.addPlanCalendarDetailRejectPagination(res.data.data.pagination);
            }, 100);
          }
        });
    },
    fetchSuccessData({ isRefresh = false } = {}) {
      this.$axios
        .get(`report/plan-calendar/${this.planCalendarId}/detail`, {
          params: {
            page: isRefresh ? 1 : this.allPlanCalendarDetailSuccessPagination.current_page,
            per_page: this.per_page,
            statuses: ['success'],
            order_by: 'piority',
            ...this.onlyFilter,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            setTimeout(() => {
              const data = this.mergedCalendars(res.data.data.data);
              this.addPlanCalendarDetailSuccessData(data);
              this.addPlanCalendarDetailSuccessPagination(res.data.data.pagination);
            }, 100);
          }
        });
    },
    fetchWaitConfirmData({ isRefresh = false } = {}) {
      this.$axios
        .get(`report/plan-calendar/${this.planCalendarId}/detail`, {
          params: {
            page: isRefresh ? 1 : this.allPlanCalendarDetailWaitConfirmPagination.current_page,
            per_page: this.per_page,
            statuses: ['wait_to_confirm'],
            order_by: 'piority',
            ...this.onlyFilter,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            setTimeout(() => {
              const data = this.mergedCalendars(res.data.data.data);
              this.addPlanCalendarDetailWaitConfirmData(data);
              this.addPlanCalendarDetailWaitConfirmPagination(res.data.data.pagination);
            }, 100);
          }
        });
    },
    fetchCancelData({ isRefresh = false } = {}) {
      this.$axios
        .get(`report/plan-calendar/${this.planCalendarId}/detail`, {
          params: {
            page: isRefresh ? 1 : this.allPlanCalendarDetailCancelPagination.current_page,
            per_page: this.per_page,
            statuses: ['canceled'],
            order_by: 'piority',
            ...this.onlyFilter,
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            setTimeout(() => {
              const data = this.mergedCalendars(res.data.data.data);
              this.addPlanCalendarDetailCancelData(data);
              this.addPlanCalendarDetailCancelPagination(res.data.data.pagination);
            }, 100);
          }
        });
    },
    statusColor(value) {
      if (value == 'pending') return 'info';
      if (value == 'success') return 'success';
      if (value == 'reject') return 'error';
      if (value == 'cancel') return 'orange';
      return 'error';
    },
    getUnit(value) {
      if (
        value == "bag" || value == "chartered" || value == "32km" || value == "infect"
      ) return "ຖົງ";
      if (value == "fix_cost") return "ຖ້ຽວ";

      if (value == "container") return "ຄອນເທັນເນີ";
      return "";
    },
    Search() {
      GetOldValueOnInput(this);
    },
    viewPage(plan_calendar, id) {
      this.$openRoute({
        name: "TrashDetail",
        params: { plan_calendar, id },
      });
    },

    openRoute(item) {
      const name = item.route_plan_detail.customer.customer_type == 'home'
        ? 'ViewClient'
        : 'ViewCompanyDetail';
      const routeData = this.$router.resolve({
        name,
        params: {
          id: item.route_plan_detail.customer.id,
        },
      });

      window.open(routeData.href);
    },

  },
  watch: {
    selectPlanCalendar(value) {
      this.addPlanCalendarId(value);
      this.isDisableExportButton = true;
      // this.fetchData({ isRefresh: true });
    },
    monthYearPicker() {
      this.fetchPlanCalendarByMonthYear();
    },
    selectedCustomerType() {
      this.fetchPlanCalendarByMonthYear();
    },
    created_date_filter() {
      // this.fetchData({ isRefresh: true });
    },
    selectedDistrict(newVal) {
      this.isDisableExportButton = true;
      if (newVal == null) {
        this.villages = [];
        this.selectedVillage = [];
        // this.fetchData({ isRefresh: true });
      } else {
        this.fetchVillage();
        // this.fetchData();
      }
    },
    selectedVillage() {
      this.isDisableExportButton = true;
      // this.fetchData({ isRefresh: true });
    },
    // search(value) {
    //   if (value == '') {
    // //     this.fetchData();
    //   }
    // },
  },
  created() {
    this.fetchAddress();
    this.fetchPlanCalendarByMonthYear();
  },
};
