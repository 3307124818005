<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          large
          class="pa-0"
        >
          ຂີ້ເຫື້ຍອທີ່ຍັກເລີກ
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="search"
          outlined
          dense
          clearable
          prepend-inner-icon="mdi-magnify"
          label="ຄົ້ນຫາສະເພາະໜ້າ"
          type="text"
        />
      </v-col>
    </v-row>
    <div>
      <v-data-table
        v-if="allPlanCalendarDetailCancelData"
        :headers="headers"
        :items="allPlanCalendarDetailCancelData"
        :search="search"
        :disable-pagination="true"
        hide-default-footer
      >
<!--        <template v-slot:item.created_at="{ item }">-->
<!--          <div>-->
<!--            {{ moment(item.created_at).format("DD-MM-YY hh:mm ") }}-->
<!--          </div>-->
<!--        </template>-->
<!--        <template v-slot:item.date="{ item }">-->
<!--          <div>-->
<!--            {{ moment(item.date).format("DD-MM-YY hh:mm:ss") }}-->
<!--          </div>-->
<!--        </template>-->
        <template v-slot:item.can_collect="{ item }">
          <v-chip
              :color="item.customer_can_collect_color"
              label
          >
            {{ item.customer_can_collect_la }}
          </v-chip>
        </template>
        <template v-slot:item.route_plan_detail.customer.full_name="{ item }">
          <a
            href="#"
            @click="openRoute(item)"
          >{{ item.route_plan_detail.customer.full_name }}</a>
        </template>
        <!--
            <template v-slot:item.start_month="{ item }">
              {{ item.route_plan_detail.customer.start_month }}
            </template>
 -->
        <template v-slot:item.status="{ item }">
          <v-chip
            color="orange"
            label
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.amount="{ item }">
          <div v-if="item.collection_type == 'bag' || item.collection_type == 'chartered' || item.collection_type == '32km' || item.collection_type == 'infect'">
            <v-chip color="primary">
              {{ item.bag }}
            </v-chip>
            <!-- <span> {{ getUnit(item.collection_type) }}</span> -->
          </div>
          <div v-else-if="item.collection_type == 'fix_cost'">
            <!-- <span> {{ getUnit(item.collection_type) }}</span> -->
          </div>
          <div v-else>
            <v-chip color="success">
              {{ item.container }}
            </v-chip>
            <!-- <span> {{ getUnit(item.collection_type) }}</span> -->
          </div>
        </template>

        <template v-slot:item.unit="{item}">
          <span> {{ getUnit(item.collection_type) }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="viewPage(item.plan_calendar_id, item.id)"
          >
            mdi-eye
          </v-icon>
        </template>
      </v-data-table><br>
      <template>
        <Pagination
          v-if="allPlanCalendarDetailCancelPagination.total_pages > 1"
          :pagination="allPlanCalendarDetailCancelPagination"
          :offset="offset"
          @paginate="fetchCancelData()"
        />
      </template>
    </div>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex';
import ReportTrashTabMixin from "@views/calendar/ReportTrashTabMixin";

export default {
  name: 'Trash',
  mixins: [ReportTrashTabMixin],
  data() {
    return {
      //   //Pagination
      headers: [
        { text: 'ລຳດັບຄວາມສຳຄັນ', value: 'priority' },
        { text: 'ໄອດີ', value: 'route_plan_detail.customer.customer_id' },
        { text: 'ລູກຄ້າ', value: 'route_plan_detail.customer.full_name' },
        { text: 'ເບີໂທ', value: 'route_plan_detail.customer.user.phone' },
        {
          text: 'ສະຖານະເກັບ',
          value: 'can_collect',
        },
        { text: 'ບ້ານ', value: 'route_plan_detail.customer.village.name' },
        { text: 'ເມືອງ', value: 'route_plan_detail.customer.village.district.name' },
        {
          text: 'ຈຳນວນຂີ້ເຫື້ຍອ',
          value: 'amount',
          align: 'center',
          sortable: false,
        },
        {
          text: 'ຫົວໜ່ວຍ',
          value: 'unit',
          align: 'center',
          sortable: false,
        },
        {
          text: 'ສະຖານະ',
          value: 'status',
          align: 'center',
          sortable: false,
        },
        {
          text: 'ວັນທີສ້າງ',
          value: 'created_at',
          align: 'center',
          sortable: false,
        },
        {
          text: 'ວັນທີເກັບ',
          value: 'collected_at',
          align: 'center',
          sortable: false,
        },
        { text: '', value: 'actions', sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(['planCalendarId', 'allPlanCalendarDetailCancelData', 'allPlanCalendarDetailCancelPagination']),
  },
};
</script>

<style lang="scss">
@import "../../../../public/scss/main.scss";
</style>
