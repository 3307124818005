<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          class="pa-0"
          large
        >
          ຂີ້ເຫື້ຍອທັງໝົດ
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model="search"
          outlined
          dense
          clearable
          prepend-inner-icon="mdi-magnify"
          label="ຄົ້ນຫາສະເພາະໜ້າ"
          type="text"
        />
      </v-col>
    </v-row>

    <v-data-table
      :disable-pagination="true"
      :headers="headers"
      :items="allPlanCalendarDetailData"
      :search="search"
      hide-default-footer
    >
      <template v-slot:item.full_name="{ item }">
        <a
          href="#"
          @click="openRoute(item)"
        >{{ item.route_plan_detail.customer.full_name }}</a>
      </template>

      <template v-slot:item.phone="{ item }">
        <span>{{ item.route_plan_detail.customer.user.phone }}</span>
      </template>
      <template v-slot:item.village_name="{ item }">
        <span>{{ item.route_plan_detail.customer.village.name }}</span>
      </template>
      <template v-slot:item.district_name="{ item }">
        <span>{{ item.route_plan_detail.customer.village.district.name }}</span>
      </template>

      <template v-slot:item.customer_id="{ item }">
        <span>{{ item.route_plan_detail.customer.customer_id }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <v-chip
          :color="item.status_color"
          label
        >
          {{ item.status }}
        </v-chip>
      </template>

      <template v-slot:item.can_collect="{ item }">
        <v-chip
          :color="item.customer_can_collect_color"
          label
        >
          {{ item.customer_can_collect_la }}
        </v-chip>
      </template>

      <template v-slot:item.amount="{item}">
        <v-chip :color="item.amount_color">
          {{ item.amount }}
        </v-chip>
      </template>
      <template v-slot:item.unit="{item}">
        <span>{{ item.amount_collection_type }}</span>
      </template>

      <template v-slot:item.is_pause="{ item }">
        <v-chip
          :color="item.is_pause_color"
          dark
          label
        >
          {{
            item.is_pause_la
          }}
        </v-chip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          class="mr-2"
          small
          @click="viewPage(item.plan_calendar_id, item.id)"
        >
          mdi-eye
        </v-icon>
        <!-- <v-icon
            class="mr-2"
            small
            @click="openDialog(item)"
          >
            mdi-plus
          </v-icon> -->
      </template>
    </v-data-table>
    <br>
    <template>
      <Pagination
        v-if="allPlanCalendarDetailPagination.total_pages > 1"
        :offset="offset"
        :pagination="allPlanCalendarDetailPagination"
        @paginate="fetchData()"
      />
    </template>
  </v-container>
</template>

<script>
// import { GetOldValueOnInput } from "@/Helpers/GetValue";
import ReportTrashTabMixin from '@/views/calendar/ReportTrashTabMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'Trash',
  mixins: [ReportTrashTabMixin],
  computed: {
    ...mapGetters(['allPlanCalendarDetailData', 'allPlanCalendarDetailPagination']),
  },
};
</script>

<style lang="scss">
@import "../../../../public/scss/main.scss";
</style>
