<template>
  <v-container>
    <v-breadcrumbs
      large
      class="pt-0"
    >
      ລາຍງານລາຍລະອຽດແຜນ
    </v-breadcrumbs>

    <!--    filter month and plan-->

    <v-row>
      <v-col cols="2">
        <v-menu
          v-model="is_month_year_picker_show"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormatted"
              label="ວັນ/ເດືອນ/ປີ"
              readonly
              outlined
              v-bind="attrs"
              dense
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="monthYearPicker"
            type="date"
          />
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="selectedCustomerType"
          outlined
          dense
          :items="customer_type_value"
          item-text="label"
          item-value="value"
          label="ປະເພດລູກຄ້າ"
          clearable
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="selectPlanCalendar"
          outlined
          dense
          :items="plan_calendar_month_year_filter_data"
          :item-text="item =>`${item.plan_month.name} | ${item.date} | ${item.driver.vehicle.car_id}(${item.driver.name}) | ${item.driver.vehicle.vehicle_type.name} | ${item.route_plan.name}`"
          item-value="id"
          label="ແຜນເດີນລົດ*"
          clearable
          auto
        />
      </v-col>
      <v-col cols="2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                :disabled="isDisableExportButton || searchButtonState"
              v-bind="attrs"
              color="success"
              v-on="on"
            >
              <!-- <v-icon class="mr-2" dark medium> mdi-refresh </v-icon> -->
              Export
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              link
              @click="downloadExelWithFilter()"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-2"
                  small
                >
                  mdi-check
                </v-icon>
                ຢືນຢັນ
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <!-- filter start -->
    <v-row>
      <v-col cols="2">
        <v-autocomplete
          v-model="selectedDistrict"
          outlined
          dense
          :items="districts"
          item-text="name"
          item-value="id"
          label="ເມືອງ"
          clearable
        />
      </v-col>
      <v-col cols="2">
        <v-autocomplete
          v-model="selectedVillage"
          outlined
          dense
          :items="villages"
          item-text="name"
          item-value="id"
          label="ບ້ານ"
          multiple
          clearable
        />
      </v-col>

      <!-- <v-col cols="3">
        <v-menu
          v-model="created_date_filter_menu"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          :disabled="planCalendarId===0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="created_date_filter"
              label="ວັນທີສ້າງ"
              readonly
              outlined
              v-bind="attrs"
              dense
              clearable
              :disabled="planCalendarId===0"
              v-on="on"
            />
          </template>
          <v-date-picker v-model="created_date_filter" />
        </v-menu>
      </v-col> -->

      <!-- <v-col cols="0" /> -->

      <v-col cols="2">
        <!-- <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }"> -->
        <v-btn
          :disabled="searchButtonState"
          color="primary"
          @click="commitSearchButton()"
        >
          <!-- <v-icon class="mr-2" dark medium> mdi-refresh </v-icon> -->
          Search
        </v-btn>
        <!-- </template> -->
        <!-- </v-menu> -->
      </v-col>
    </v-row>

    <!-- filter end -->

    <v-card elevation="1">
      <v-card-text>
        <v-row>
          <v-col>
            <v-tabs v-model="tab">
              <v-tab
                href="#tab-1"
              >
                ທັງໝົດ
              </v-tab>
              <v-tab href="#tab-2">
                <v-badge
                  color="info"
                  :content="summary.pending_count"
                >
                  ລໍຖ້າເກັບ
                </v-badge>
              </v-tab>
              <v-tab href="#tab-3">
                <v-badge
                  color="warning"
                  :content="summary.wait_to_confirm_count"
                >
                  ລໍຖ້າຢືນຢັນ
                </v-badge>
              </v-tab>
              <v-tab href="#tab-4">
                <v-badge
                  color="success"
                  :content="summary.success_count"
                >
                  ສຳເລັດ
                </v-badge>
              </v-tab>

              <v-tab href="#tab-5">
                <v-badge
                  color="red"
                  :content="summary.reject_count"
                >
                  ປະຕິເສດການເກັບ
                </v-badge>
              </v-tab>
              <v-tab href="#tab-6">
                <v-badge
                  color="orange"
                  :content="summary.canceled_count"
                >
                  ຍົກເລີກການເກັບ
                </v-badge>
              </v-tab>
            </v-tabs>
            <!-- <hr /> -->

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1">
                <allTrash />
              </v-tab-item>
            </v-tabs-items>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-2">
                <v-card flat>
                  <v-card-text>
                    <pendingTrash />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-3">
                <v-card flat>
                  <v-card-text>
                    <waitToConfirmTrash />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-4">
                <v-card flat>
                  <v-card-text>
                    <successTrash />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-5">
                <v-card flat>
                  <v-card-text>
                    <rejectTrash />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-6">
                <v-card flat>
                  <v-card-text>
                    <cancel-trash />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import allTrash from "@views/calendar/ReportCalendarIndexTab/allTrash";
import pendingTrash from "@views/calendar/ReportCalendarIndexTab/pendingTrash";
import successTrash from "@views/calendar/ReportCalendarIndexTab/successTrash";
import waitToConfirmTrash from "@views/calendar/ReportCalendarIndexTab/waitConfirmTrash";
import rejectTrash from "@views/calendar/ReportCalendarIndexTab/rejectTrash";
import cancelTrash from "@views/calendar/ReportCalendarIndexTab/cancelTrash";
import ReportTrashTabMixin from "@/views/calendar/ReportTrashTabMixin";
// import queryOption from "@/Helpers/queryOption";
import { mapGetters } from "vuex";

export default {
  title() {
    return `Vientiane Waste Co-Dev|Calendar`;
  },
  components: {
    allTrash,
    pendingTrash,
    successTrash,
    waitToConfirmTrash,
    rejectTrash,
    cancelTrash,
  },
  mixins: [ReportTrashTabMixin],
  props: ["plan"],
  data() {
    return {
      tab: null,
    };
  },
  methods: {
  },
  computed: {
    searchButtonState() {
      if ((this.planCalendarId !== 0 && this.planCalendarId != null)) {
        return false;
      }
      return true;
    },
    ...mapGetters(['planCalendarId']),
    dateFormatted: {
      get() {
        this.addPlanCalendarId(0);
        return this.monthYearPicker ? this.formatDate(this.monthYearPicker) : '';
      },
      set(newValue) {
        this.monthYearPicker = new Date(newValue);
      },
    },
  },
  watch: {
    tab(value) {
      if (value == "tab-1") {
        this.$router
          .push({
            name: "ReportBagPlanCalendarDetail",
            query: { tab: "trash-all" },
          })
          .catch(() => {});
      } else if (value == "tab-2") {
        this.$router
          .push({
            name: "ReportBagPlanCalendarDetail",
            query: { tab: "trash-pending" },
          })
          .catch(() => {});
      } else if (value == "tab-3") {
        this.$router
          .push({
            name: "ReportBagPlanCalendarDetail",
            query: { tab: "wait-to-confirm" },
          })
          .catch(() => {});
      } else if (value == "tab-4") {
        this.$router
          .push({
            name: "ReportBagPlanCalendarDetail",
            query: { tab: "trash-success" },
          })
          .catch(() => {});
      } else if (value == "tab-5") {
        this.$router
          .push({
            name: "ReportBagPlanCalendarDetail",
            query: { tab: "trash-reject" },
          })
          .catch(() => {});
      } else if (value == "tab-6") {
        this.$router
          .push({
            name: "ReportBagPlanCalendarDetail",
            query: { tab: "trash-cancel" },
          })
          .catch(() => {});
      }
    },
  },
  created() {
    if (this.$route.query.tab == "trash-all") {
      this.tab = "tab-1";
    } else if (this.$route.query.tab == "trash-pending") {
      this.tab = "tab-2";
    } else if (this.$route.query.tab == "wait-to-confirm") {
      this.tab = "tab-3";
    } else if (this.$route.query.tab == "trash-success") {
      this.tab = "tab-4";
    } else if (this.$route.query.tab == "trash-reject") {
      this.tab = "tab-5";
    } else if (this.$route.query.tab == "trash-cancel") {
      this.tab = "tab-6";
    }
  },
};
</script>

<style scoped>
</style>
